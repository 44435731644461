import React from 'react'

import { Trans } from '@lingui/macro'
import Modal from '../../../../../generic/Modal'
import Card from '../../../../../generic/Card'
import LoadingWrapper from '../../../../../generic/LoadingWrapper'
import SmallTitle from '../../../../../generic/SmallTitle'
import { FiX } from 'react-icons/fi'
import Button from '../../../../../generic/Button'
import useSubmit from '../../../../account/index/UpdateAvatarButton/hooks/useSubmit'
import AvatarEditor from 'react-avatar-editor'

export default function UploadAvatarButton({
  inputRef,
  preview,
  handleChange,
  handleOpen,
  updateValues,
  values,
  reset,
  editorRef,
  handleSubmit,
  loading,
}) {
  return (
    <div>
      <input key={values.file} ref={inputRef} type="file" className="hidden" onChange={handleChange} />
      <button type="button" onClick={handleOpen} className="text-gray-800 uppercase text-xs font-bold mt-2">
        Cambiar foto de perfil
      </button>

      <Modal
        open={values.showModal}
        maxWidth={450}
        onClose={() => (!loading ? updateValues('showModal', false) : null)}
      >
        <Card className="overflow-hidden">
          <LoadingWrapper loading={loading}>
            <Card.Body>
              <div className="flex items-center justify-between text-gray-800">
                <SmallTitle
                  renderOption={
                    <button className="text-lg leading-none" onClick={() => updateValues('showModal', false)}>
                      <FiX />
                    </button>
                  }
                >
                  <Trans>Ajusta tu foto</Trans>
                </SmallTitle>
              </div>

              <div className="flex flex-col items-center my-2">
                <AvatarEditor
                  ref={editorRef}
                  image={preview}
                  border={50}
                  borderRadius={125}
                  color={[255, 255, 255, 0.6]} // RGBA
                  scale={values.zoom}
                  rotate={0}
                  className="bg-gray-400 rounded-lg overflow-hidden"
                />
                <div className="py-2">
                  <input
                    type="range"
                    min="0.2"
                    max="1.8"
                    step="0.01"
                    value={values.zoom}
                    onChange={(e) => updateValues('zoom', e.target.value)}
                  />
                </div>
              </div>
            </Card.Body>
            <div className="p-1">
              <Button
                onClick={(e) => {
                  updateValues('showModal', false)
                  handleSubmit(e)
                }}
                spacingClassName="px-3 py-2"
                className="text-sm w-full rounded-md"
              >
                Usar imagen
              </Button>
            </div>
          </LoadingWrapper>
        </Card>
      </Modal>
    </div>
  )
}
