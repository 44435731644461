import React from 'react'
import classnames from 'classnames'

export default function SmallTitle({ children, className, renderOption }) {
  return (
    <div
      className={classnames(
        'flex w-full items-center justify-between border-b border-gray-300 pb-2',
        className || 'mb-5'
      )}
    >
      <h1 className="text-gray-800 font-bold">{children}</h1>
      {renderOption ? renderOption : null}
    </div>
  )
}
