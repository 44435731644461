import React from 'react'
import { t } from '@lingui/macro'
import Layout from './Layout'
import Link from 'next/link'
import AccessLayoutLanding from './AccessLayoutLanding'

export default function AccessLayout({ children }) {
  return (
    <Layout
      SEOProps={{ title: t`Bienvenido/a` }}
      showFooter={false}
      showNavBar={false}
      className="flex flex-col bg-white w-full"
      style={{ minHeight: 'calc(100vh - 53px)' }}
      requireAccessIfAnonymous={false}
    >
      <div className="w-full flex flex-col md:flex-row">
        <div className="w-full flex flex-col-reverse md:flex-row">
          <div className="lg:flex-1 bg-orange-500 overflow-hidden-z relative">
            <div className="flex lg:h-full items-center justify-center p-6 sm:p-12">
              <img
                src="/static/images/netMinISOWhite.svg"
                alt="Netmin Logo"
                className="absolute bottom-0 left-0 w-full transform scale-110 opacity-25 select-none pointer-events-none "
              />
              <div>
                <ul className="text-white font-black space-y-4 text-base sm:text-xl">
                  <li>
                    <img
                      src="/static/images/access/follow.svg"
                      className="w-5 sm:w-8 inline mr-2"
                      alt="Icono de followers"
                    />{' '}
                    Sigue lo que te interesa
                  </li>
                  <li>
                    <img
                      src="/static/images/access/search.svg"
                      className="w-5 sm:w-8 inline mr-2"
                      alt="Icono de followers"
                    />{' '}
                    Descubre nuevos Productos
                  </li>
                  <li>
                    <img
                      src="/static/images/access/webinars.svg"
                      className="w-5 sm:w-8 inline mr-2"
                      alt="Icono de followers"
                    />{' '}
                    Capacítate con webinars gratuitos
                  </li>
                  <li>
                    <img
                      src="/static/images/access/handshake.svg"
                      className="w-5 sm:w-8 inline mr-2"
                      alt="Icono de followers"
                    />{' '}
                    Colabora con el sector
                  </li>
                  <li>
                    <img
                      src="/static/images/access/update.svg"
                      className="w-5 sm:w-8 inline mr-2"
                      alt="Icono de followers"
                    />{' '}
                    Mantente actualizado/a
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="lg:flex-1 lg:h-full py-12">
            <div className="flex flex-col justify-center h-full items-center px-6">
              <div className="w-full max-w-md">
                <div className="w-full">
                  <div className="mb-8">
                    <img src="/static/images/netMin.svg" alt="Netmin Logo" className="h-8 sm:h-10" />
                  </div>
                  {children}
                  <small className="text-gray-500 text-center block mt-5 leading-none">
                    Nos preocupamos de tus datos. Aquí está nuestra{' '}
                    <Link href="/pages/privacy">
                      <a className="underline" target="_blank" rel="noopener noreferrer">
                        política de privacidad
                      </a>
                    </Link>
                  </small>
                </div>
              </div>

              <div className="block md:hidden mt-10 px-2 text-center">
                <p className="font-black text-gray-800 leading-tight mb-2">
                  O descarga la app y mejora la experiencia:
                </p>
                <div className="flex flex-wrap justify-center">
                  <a href="https://play.google.com/store/apps/details?id=cl.netmin" target="blank" className="p-1">
                    <img src="/static/images/browsers/playstore.png" className="h-10 mx-auto" />
                  </a>

                  <a href="https://apps.apple.com/us/app/netmin/id1608373176" target="blank" className="p-1">
                    <img src="/static/images/browsers/appstore.png" className="h-10 mx-auto" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AccessLayoutLanding />
    </Layout>
  )
}
