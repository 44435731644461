import React from 'react'
import Tippy from '@tippyjs/react'

export default function AccessLayoutSponsors() {
  return (
    <div className="flex justify-center w-full">
      <div>
        <p className="font-bold text-gray-800 leading-tight text-center">
          Con el patrocinio de instituciones del sector:
        </p>

        <div className="flex flex-wrap items-center justify-center space-x-4 mt-3 w-full">
          <Tippy content="Ministerio de Minería" theme="light" placement="bottom">
            <button>
              <img src="/static/images/sponsors/mineria.png" className="h-12 sm:h-16 py-2" />
            </button>
          </Tippy>
          <Tippy content="Comisión Chilena del Cobre" theme="light" placement="bottom">
            <button>
              <img src="/static/images/sponsors/cochilco.png" className="h-12 sm:h-16 py-2" />
            </button>
          </Tippy>
          <Tippy content="SERNAGEOMIN" theme="light" placement="bottom">
            <button>
              <img src="/static/images/sponsors/sernageomin.png" className="h-12 sm:h-16 py-2" />
            </button>
          </Tippy>
          <Tippy content="CORFO" theme="light" placement="bottom">
            <button>
              <img src="/static/images/sponsors/corfo.png" className="h-12 sm:h-16 py-2" />
            </button>
          </Tippy>
          <Tippy content="APRIMIN" theme="light" placement="bottom">
            <button>
              <img src="/static/images/sponsors/aprimin.png" className="h-12 sm:h-16 py-2" />
            </button>
          </Tippy>
          <Tippy content="Minnovex" theme="light" placement="bottom">
            <button>
              <img src="/static/images/sponsors/minnovex.png" className="h-12 sm:h-16 py-2" />
            </button>
          </Tippy>
          <Tippy content="Advanced Mining Technology Center" theme="light" placement="bottom">
            <button>
              <img src="/static/images/sponsors/amtc.png" className="h-12 sm:h-16 py-2" />
            </button>
          </Tippy>
          <Tippy content="Alta Ley" theme="light" placement="bottom">
            <button>
              <img src="/static/images/sponsors/altaley.png" className="h-12 sm:h-16 py-2" />
            </button>
          </Tippy>
        </div>
      </div>
    </div>
  )
}
