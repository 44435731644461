import useValues from '../../../../../../hooks/useValues'
import { useAuth } from '../../../../../../providers/authentication/context'
import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'

export default function useProcess() {
  const { query } = useRouter()
  const { signIn } = useAuth()
  const { values, updateValues, reset } = useValues({ slide: 0 })
  const [signUpEnabled, setSignUpEnabled] = useState(true)

  const handleEmailSuccess = ({ email, result }) => {
    updateValues({ slide: 2, email, exists: result.Exists, hasAuth: result.HasAuth })
  }

  const handleSignUpSuccess = ({ password }) => {
    updateValues({ slide: 3, password })
  }

  const handleSignInSuccess = (token) => {
    signIn(token)
  }

  const handlePasswordRecovery = () => {
    updateValues({ slide: 1, recovery: true })
  }

  const handleStart = () => {
    updateValues('slide', 1)
  }

  const handleReset = () => {
    setSignUpEnabled(true)
    reset()
    updateValues({ slide: 1, recovery: false })
  }

  const handleHardReset = () => {
    setSignUpEnabled(true)
    reset()
    updateValues({ slide: 0, recovery: false })
  }

  useEffect(() => {
    if (query['recoveryEmail']) {
      updateValues({ slide: 1, recovery: true, email: query['recoveryEmail'] })
    }
  }, [])

  return {
    values,
    updateValues,
    signUpEnabled,
    handleEmailSuccess,
    handleSignInSuccess,
    handleSignUpSuccess,
    handlePasswordRecovery,
    reset: handleReset,
    hardReset: handleHardReset,
    handleStart,
    setSignUpEnabled,
  }
}
