import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import * as yup from 'yup'
import useSubmit from './hooks/useSubmit'
import { TextInput } from 'burton'
import Button from '../../../../generic/Button'
import LoadingWrapper from '../../../../generic/LoadingWrapper'

export default function EmailForm({ onSuccess, onReset }) {
  const { handleSubmit: handleFormSubmit, control, errors } = useForm({
    resolver: yupResolver(validationSchema),
  })
  const { handleSubmit, loading } = useSubmit({ onSuccess })

  return (
    <LoadingWrapper loading={loading}>
      <form onSubmit={handleFormSubmit(handleSubmit)}>
        <p className="font-black text-gray-700 leading-tight">Ingresa tu correo electrónico personal:</p>
        <Controller
          name="Email"
          type="email"
          as={TextInput}
          label="Correo electrónico"
          error={errors.Email?.message}
          disabled={loading}
          control={control}
          autoFocus
        />
        <Button className="block w-full  mt-3">Comprobar</Button>
      </form>
      <button onClick={onReset} className="text-orange-500 active:text-orange-700 mx-auto text-sm block py-2">
        Volver atrás
      </button>
    </LoadingWrapper>
  )
}

const validationSchema = yup.object().shape({
  Email: yup
    .string()
    .email('Correo electrónico no es válido')
    .required('Correo electrónico es requerido')
    .transform((val) => (val ? val.trim().toLowerCase() : val)),
})
