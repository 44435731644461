import React from 'react'
import classnames from 'classnames'
import { motion, AnimatePresence } from 'framer-motion'

export default function Slide({ currentIndex, active, children }) {
  const position = `${-1 * currentIndex * 100}%`

  return (
    <motion.div
      animate={{ x: position }}
      transition={{ duration: 0.7, ease: [0.16, 1, 0.3, 1] }}
      className="relative inline-flex whitespace-normal w-full max-w-full align-top"
    >
      <div className={classnames('overflow-hidden w-full top-0 left-0', active ? 'relative' : 'absolute')}>
        <AnimatePresence>
          {active ? (
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
              {React.cloneElement(children, { active })}
            </motion.div>
          ) : null}
        </AnimatePresence>
      </div>
    </motion.div>
  )
}
