import React from 'react'
import Container from '../generic/Container'

export default function DownloadNewAppAlert() {
  const handleClick = (href) => {
    window.ReactNativeWebView?.postMessage?.(JSON.stringify({ type: 'external', href }))
  }

  return (
    <Container maxWidth={450}>
      <div className="flex flex-col items-center justify-center space-y-8 py-8">
        <img src="/static/images/netMinISO.svg" alt="Netmin Logo" className="w-20" />

        <div>
          <p className="text-2xl font-semibold text-gray-800 text-center">¡Tenemos nueva app móvil!</p>

          <p className="text-base text-gray-700 text-center">
            Hemos creado una nueva app móvil para mejorar la experiencia de uso y la que estás usando actualmente dejará
            de estar disponible.
          </p>
        </div>

        <ul className="text-base text-gray-700 list-disc pl-4">
          <li>Es más rápida y tiene un nuevo diseño</li>
          <li>Ocupa menos espacio</li>
          <li>Funciona en todos los dispositivos</li>
          <li>Soluciona los problemas de la app antigua</li>
          <li>Y mucho más</li>
        </ul>

        <p className="text-lg font-semibold text-gray-800 text-center">¡Descargar aquí!</p>

        <div className="flex flex-wrap justify-center">
          <button
            onClick={() => handleClick('https://play.google.com/store/apps/details?id=cl.netmin')}
            className="p-1"
          >
            <img src="/static/images/browsers/playstore.png" className="h-10 mx-auto" />
          </button>

          <button onClick={() => handleClick('https://apps.apple.com/us/app/netmin/id1608373176')} className="p-1">
            <img src="/static/images/browsers/appstore.png" className="h-10 mx-auto" />
          </button>
        </div>
      </div>
    </Container>
  )
}
