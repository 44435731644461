import React, { useEffect } from 'react'
import dynamic from 'next/dynamic'

const Container = dynamic(() => import('./components/Container'), { ssr: false })

export default function Modal({
  open,
  onClose,
  children,
  maxWidth,
  animate = false,
  showCloseButton,
  verticalSpacingClassName,
  closeOnOutsideClick = true,
  ...rest
}) {
  useEffect(() => {
    const handleKeydown = (e) => {
      if (e.key === 'Escape' || e.key === 'Esc' || e.keyCode === 27) {
        e.preventDefault()
        onClose && onClose()
        return false
      }
    }

    window.addEventListener('keydown', handleKeydown)
    return () => {
      window.removeEventListener('keydown', handleKeydown)
    }
  }, [])

  useEffect(() => {
    if (open) {
      document.body.classList.add('overflow-hidden')
    } else {
      document.body.classList.remove('overflow-hidden')
    }

    return () => {
      document.body.classList.remove('overflow-hidden')
    }
  }, [open])

  return (
    <Container
      animate={animate}
      open={open}
      onClose={onClose}
      maxWidth={maxWidth}
      showCloseButton={showCloseButton}
      verticalSpacingClassName={verticalSpacingClassName}
      closeOnOutsideClick={closeOnOutsideClick}
      {...rest}
    >
      {children}
    </Container>
  )
}
