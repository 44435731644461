import numbro from 'numbro'
import React from 'react'

const CounterItem = ({ text, count }) => {
  return (
    <div className="flex flex-col items-center">
      <p className="text-orange-500 font-bold text-lg sm:text-5xl">
        +{numbro(count).format({ thousandSeparated: true })}
      </p>
      <p className="text-md sm:text-3xl font-semibold text-gray-800">{text}</p>
    </div>
  )
}

export default CounterItem
