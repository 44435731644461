import React, { useState } from 'react'
import Button from '../../../../generic/Button'
import { TextInput } from 'burton'
import { earlyAccessCodes } from '../../../../../config'
import { toast } from 'react-toastify'

export default function SoonMessage({ onReset, onAccess }) {
  const [code, setCode] = useState('')

  const handleSubmit = (e) => {
    e.preventDefault()
    if (earlyAccessCodes.find((accessCode) => code.startsWith(accessCode))) {
      onAccess()
    } else {
      toast.error('El código ingresado no es correcto.')
    }
  }

  return (
    <div>
      <div className="rounded-lg p-4 sm:p-8 flex-items-center justify-center bg-gray-200">
        <p className="text-gray-700 leading-none text-sm sm:text-base text-center tracking-wide font-semibold">
          La creación de cuentas aún no está habilitada. Síguenos en redes sociales para saber más.
        </p>
      </div>

      <div className=" max-w-sm mx-auto my-10 border border-gray-200 rounded-lg px-3 sm:px-6 py-6 shadow-minex">
        <p className="text-gray-700 leading-none text-sm  tracking-wide font-semibold">
          Si tienes algún código de acceso anticipado ingrésalo a continuación
        </p>
        <form onSubmit={handleSubmit}>
          <TextInput label="Código de acceso anticipado" value={code} onChange={setCode} />
          <Button type="submit" className="block w-full">
            Continuar
          </Button>
        </form>
      </div>

      <button
        type="button"
        className="text-orange-500 active:text-orange-700 block mx-auto my-4 text-sm"
        onClick={onReset}
      >
        Probar con otro correo electrónico
      </button>
    </div>
  )
}
