import Service from './Service'
import CompaniesService from './CompaniesService'

export default class AuthenticationService extends Service {
  signInWithEmailAndPassword = async (email, password) => {
    return await this._post('/users/sign-in/local', { Email: email, Password: password })
  }

  signInWithSignInTokenUUID = async (signInTokenUUID) => {
    return await this._post('/users/sign-in/sst', { SignInTokenUUID: signInTokenUUID })
  }

  //PASS AUTH CODE
  signInWithGoogle = (code, redirectURI, language = 'es') => {
    return this._post('/users/sign-in/oauth', {
      Code: code,
      Provider: 'Google',
      Redirect: redirectURI,
      Language: language,
    })
  }

  linkGoogleToAccount = (code, redirectURI, userUUID) => {
    return this._post(`/users/${userUUID}/link-oauth`, {
      Code: code,
      Provider: 'Google',
      Redirect: redirectURI,
    })
  }

  unlinkGoogleToAccount = (userUUID) => {
    return this._post(`/users/${userUUID}/unlink-oauth`, {
      Provider: 'Google',
    })
  }

  //PASS AUTH CODE
  signInWithLinkedIn = (code, redirectURI, language = 'es') => {
    return this._post('/users/sign-in/oauth', {
      Code: code,
      Redirect: redirectURI,
      Provider: 'LinkedIn',
      Language: language,
    })
  }

  linkLinkedInToAccount = (code, redirectURI, userUUID) => {
    return this._post(`/users/${userUUID}/link-oauth`, {
      Code: code,
      Provider: 'LinkedIn',
      Redirect: redirectURI,
    })
  }

  unlinkLinkedInToAccount = (userUUID) => {
    return this._post(`/users/${userUUID}/unlink-oauth`, {
      Provider: 'LinkedIn',
    })
  }

  signUpWithValues = async (values) => {
    return await this._post('/users', values)
  }

  acceptTOS = async (UUID) => {
    return await this._post(`/users/${UUID}/accept-policy`)
  }

  findUserByUUID = async (uuid) => {
    return await this._get(`/users/${uuid}?include=Blocks`)
  }

  findUserByUUIDWithTOS = async (uuid) => {
    return await this._get(`/users/${uuid}?include=Policy`)
  }

  findUserByUUIDWithLinks = async (uuid) => {
    return await this._get(`/users/${uuid}?include=Links`)
  }

  checkUserByEmail = async (email) => {
    return await this._get(`/users/exists?Email=${encodeURIComponent(email)}`)
  }

  verifyAccountByVerifyToken = async (verifyToken) => {
    return await this._post('/users/verify', { VerifyToken: verifyToken })
  }

  updateUserByUUID = async (uuid, values) => {
    return await this._put(`/users/${uuid}`, values)
  }

  updateUserPassword = async (values) => {
    return await this._post('/users/change-password', values)
  }

  findAllRolesByUserUUID = async (userUUID) => {
    return await this._get(`/users/roles?UserUUID=${userUUID}`)
  }

  requestPasswordRecoveryWithEmailAndReturnURL = async (email, returnURL) => {
    return await this._post('/users/request-recovery', { Email: email, ReturnURL: returnURL })
  }

  confirmPasswordRecoveryRecoveryTokenAndPassword1AndPassword2 = async (recoveryToken, password1, password2) => {
    return await this._post('/users/confirm-recovery', {
      RecoveryToken: recoveryToken,
      Password1: password1,
      Password2: password2,
    })
  }

  findUserAndRolesByUUID = async (uuid) => {
    const companiesService = new CompaniesService(this._authToken)
    const result = await Promise.all([this.findUserByUUIDWithTOS(uuid), this.findAllRolesByUserUUID(uuid)])
    const companies = await Promise.all(result[1].map((role) => companiesService.findCompanyByUUID(role.ResourceUUID)))
    return { ...result[0], Companies: companies }
  }

  requestVerificationByUserUUID = async (userUUID) => {
    return await this._post(`/users/${userUUID}/send-verification`)
  }

  searchUsersByQuery = async (query) => {
    return await this._get(`/users/search?Query=${query}&offset=0&limit=10&order=-Similarity`)
  }

  certifyUserByUUID = async (uuid) => {
    return await this._post(`/users/${uuid}/certify`)
  }

  activateUserByUUID = async (uuid) => {
    return await this._post(`/users/${uuid}/activate`)
  }

  deactivateUserByUUIDAndReason = async (uuid, reason) => {
    return await this._post(`/users/${uuid}/deactivate`, { Reason: reason })
  }

  deleteUserByUUID = async (uuid, Code) => {
    return await this._post(`/users/${uuid}/delete`, { Code })
  }

  requestDeleteCode = async () => {
    return await this._post(`/users/request-delete-code`)
  }

  reportUserByUUIDAndReason = async (uuid, reason) => {
    return await this._post(`/users/${uuid}/report`, { Reason: reason })
  }
}
