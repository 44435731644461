import React from 'react'
import Slider from '../../../../generic/Slider'
import EmailForm from '../EmailForm'
import useProcess from './hooks/useProcess'
import SignUpForm from '../SignUpForm'
import SignUpSuccess from '../SignUpSuccess'
import SignInForm from '../SignInForm'
import PasswordRecoveryForm from '../PasswordRecoveryForm'
import SoonMessage from '../SoonMessage'
import Home from '../Home'

export default function AccessProcess() {
  const {
    values,
    signUpEnabled,
    handleEmailSuccess,
    handleSignInSuccess,
    handleSignUpSuccess,
    handlePasswordRecovery,
    reset,
    hardReset,
    handleStart,
    setSignUpEnabled,
  } = useProcess()

  return (
    <Slider slide={values.slide}>
      <Slider.Slide>
        <Home onStart={handleStart} />
      </Slider.Slide>
      <Slider.Slide>
        {values.recovery ? (
          <PasswordRecoveryForm email={values.email} onReset={reset} />
        ) : (
          <EmailForm onSuccess={handleEmailSuccess} onReset={hardReset} />
        )}
      </Slider.Slide>
      <Slider.Slide>
        {values.exists ? (
          <SignInForm
            email={values.email}
            onSuccess={handleSignInSuccess}
            onRecover={handlePasswordRecovery}
            onReset={reset}
          />
        ) : (
          <React.Fragment>
            {signUpEnabled ? (
              <SignUpForm email={values.email} onSuccess={handleSignUpSuccess} onReset={reset} />
            ) : (
              <SoonMessage onReset={reset} onAccess={() => setSignUpEnabled(true)} />
            )}
          </React.Fragment>
        )}
      </Slider.Slide>
      <Slider.Slide>
        <SignUpSuccess email={values.email} password={values.password} onSuccess={handleSignInSuccess} />
      </Slider.Slide>
    </Slider>
  )
}
