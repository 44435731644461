import useService from '../../../../../../services/hooks/useService'
import AuthenticationService from '../../../../../../services/AuthenticationService'
import useRequest from '../../../../../../services/hooks/useRequest'
import useError from '../../../../../../services/hooks/useError'

export default function useSignIn({ email, password, onSuccess }) {
  const authenticationService = useService(AuthenticationService)
  const { exec, loading, error } = useRequest(authenticationService.signInWithEmailAndPassword)
  useError(error, 'sign-in')

  const handleSignIn = async () => {
    const result = await exec(email, password)
    if (result?.Token) {
      onSuccess && onSuccess(result.Token)
    }
  }

  return { handleSignIn, loading }
}
