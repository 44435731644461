import React, { useEffect, useState } from 'react'
import { modalRoutes } from '../../../config'
import Modal from '../Modal'
import { useStore } from '../../../providers/store/context'
import DownloadNewAppAlert from '../../shared/DownloadNewAppAlert'
import { useRouter } from 'next/router'

/**
 * Given a new page from next router, decide whether to display the inside a modal or not.
 * @returns {*}
 * @constructor
 */
export default function PageManager({ page, pathname, query }) {
  const [stack, setStack] = useState([{ page, pathname }])
  const { store } = useStore()
  const { pathname: routerPathname } = useRouter()

  useEffect(() => {
    const existingIndex = stack.findIndex((item) => item.pathname === pathname)

    // If the new route do not exists in the stack
    if (existingIndex === -1) {
      // Checks if the new route should be displayed as a modal.
      // You can use asPage to avoid a modal.
      if (
        modalRoutes.reduce((base, current) => (new RegExp(current).test(pathname) ? 1 : 0) + base, 0) > 0 &&
        !query.asPage
      ) {
        setStack([...stack, { page, pathname }])
      } else {
        setStack([{ page, pathname }])
      }
    } else {
      // Remove all routes on top of the existing route
      const newStack = [...stack]
      newStack.length = existingIndex + 1
      setStack(newStack)
    }
  }, [page, pathname])

  if (store?.mode === 'webview' && routerPathname === '/') {
    return <DownloadNewAppAlert />
  }

  return stack.map((item, index) => {
    return (
      <React.Fragment key={index}>
        {index === 0 ? (
          <div aria-hidden={stack.length > index}>{item.page}</div>
        ) : (
          <Modal open onClose={() => window.history.back()} closeOnOutsideClick={false}>
            {React.cloneElement(item.page, { modal: true, onClose: () => window.history.back() })}
          </Modal>
        )}
      </React.Fragment>
    )
  })
}
