import { i18n } from '@lingui/core'
import { en, es } from 'make-plural/plurals'
import { getCookie, setCookie } from './cookies'
import { globalI18n } from '../providers/language/context'
import { messages as enMessages } from '../locale/en/messages'
import { messages as esMessages } from '../locale/es/messages'

const defaultLanguage = 'es'
const allowedLanguages = ['es', 'en']

i18n.loadLocaleData('en', { plurals: en })
i18n.loadLocaleData('es', { plurals: es })

const messages = {
  en: enMessages,
  es: esMessages,
}

export const activate = (locale) => {
  if (allowedLanguages.indexOf(locale) === -1) {
    locale = defaultLanguage
  }
  i18n.load(locale, messages[locale])
  i18n.activate(locale)
  setCookie('userlang', locale, 365)
}

export const getUserLanguage = (ctx) => {
  let lang = null
  if (typeof window !== 'undefined') {
    lang = navigator?.language || navigator?.userLanguage
  }

  const acceptedLanguages = ctx?.req?.headers?.['accept-language']
  if (acceptedLanguages && acceptedLanguages.split(';')) {
    const userLanguage = acceptedLanguages.split(';')[0]
    const specificLanguage = userLanguage.split(',')[0]
    if (specificLanguage) {
      lang = specificLanguage
    }
  }

  const cookieLang = getCookie('userlang', ctx?.req)
  if (cookieLang) {
    lang = cookieLang
  }

  return lang ? lang.substring(0, 2) : 'es'
}

export const getLocaleText = (variants) => {
  if (!variants || variants === '') {
    return ''
  }

  if (Object.prototype.hasOwnProperty.call(variants, globalI18n.locale) && variants[globalI18n.locale]) {
    return variants[globalI18n.locale]
  }

  return variants[defaultLanguage] || ''
}
