import React, { forwardRef } from 'react'
import classnames from 'classnames'

const OutlinedButton = forwardRef(function OutlinedButton(
  { children, asComponent, borderClassName, className, textClassName, ...rest },
  ref
) {
  const Element = asComponent || 'button'

  return (
    <Element
      ref={ref}
      className={classnames(
        'block text-white bg-transparent hover:bg-gray-100 active:bg-gray-200 cursor-pointer',
        borderClassName || 'border border-gray-300',
        textClassName || 'text-gray-600 text-xs sm:text-sm',
        className || 'px-5 py-2 rounded'
      )}
      {...rest}
    >
      {children}
    </Element>
  )
})

export default OutlinedButton
