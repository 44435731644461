import React from 'react'
import Animation from '../../../../generic/Animation'
import Button from '../../../../generic/Button'
import { useRouter } from 'next/router'
import { Trans } from '@lingui/macro'

export default function PasswordRecoverySuccess({ email = 'marcelo@example.com', onReset }) {
  const { query } = useRouter()
  return (
    <div>
      <div className="bg-orange-500 rounded-lg p-4 flex items-center justify-center flex-col sm:flex-row">
        <Animation name="success" width={70} height={70} />
        <div className="ml-0 sm:ml-5 mt-3 sm:mt-0 text-white text-center sm:text-left">
          <h2 className="font-semibold leading-tight text-xl sm:text-2xl">¡Muy bien!</h2>
          <h3 className="font-semibold leading-tight text-sm sm:text-base">Revisa tu bandeja de correos</h3>
        </div>
      </div>
      <div className="rounded-lg p-4 flex-items-center justify-center bg-gray-200 mt-5">
        <p className="text-gray-800 leading-none text-sm sm:text-base">
          Hemos enviado un correo electrónico con instrucciones para recuperar tu contraseña a <b>{email}</b>.
        </p>

        <p className="text-gray-800 leading-none mt-5 text-sm sm:text-base">
          Si no encuentras el correo, te recomendamos buscar en tu bandeja de SPAM.
        </p>
      </div>

      {query.recoveryEmail ? (
        <div className="rounded-lg bg-gray-200 mt-2 p-4">
          <p>
            <Trans>Una vez cambiada tu contraseña, podrás volver a la aplicación e iniciar sesión.</Trans>
          </p>
        </div>
      ) : (
        <Button className="block w-full mt-5" onClick={onReset}>
          Volver al inicio de sesión
        </Button>
      )}
    </div>
  )
}
