import React from 'react'
import classnames from 'classnames'
import Spinner from '../Spinner'

export default function LoadingWrapper({ children, as, loading, className, style, label }) {
  const Element = as || 'div'
  return (
    <Element className={classnames(className || 'relative')} style={style}>
      {children}
      {loading ? (
        <div className="absolute top-0 left-0 w-full h-full bg-white bg-opacity-50 z-30 flex items-center justify-center fade-in">
          {label ? <p className="text-gray-700"> {label} </p> : null}
          <Spinner className="text-gray-700" size={30} />
        </div>
      ) : null}
    </Element>
  )
}
