import React from 'react'
import OutlinedButton from './OutlinedButton'
import Button from './Button'
import CounterItem from './CounterItem'
import LandingInformationItem from './LandingInformationItem'
import AccessLayoutSponsors from './AccessLayoutSponsors'

export default function AccessLayoutLanding() {
  const informationText = [
    {
      title: 'Siempre conectados',
      body: 'En un ambiente web y aplicación móvil, creado para el marketing estratégico abierto al mundo minero.',
      image: '/static/images/landing/1.jpeg',
    },
    {
      title: 'Oportunidades',
      body:
        'Para activar y promover la colaboración, el conocimiento y compartir experiencias de todas las especialidades que componen la cadena de valor de la industria minera.',
      image: '/static/images/landing/2.jpg',
    },
    {
      title: 'Participa en eventos',
      body:
        'Ya sea conferencias en línea o presenciales y también accede a todas las exposiciones y entrevistas realizadas a la fecha.',
      image: '/static/images/landing/3.jpg',
    },
    {
      title: 'Comunica a la industria',
      body:
        'Desarrollos tecnológicos y propuestas de soluciones operacionales, publicando, programando conferencias en línea y encuentros de negocios.',
      image: '/static/images/landing/4.jpg',
    },
  ]

  const scrollUp = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }
  return (
    <>
      <main className="flex flex-col items-center gap-12 sm:gap-16">
        <div className="hidden md:block mt-4 px-2">
          <AccessLayoutSponsors />
        </div>
        <div className="block md:hidden mt-4 px-2 text-center">
          <AccessLayoutSponsors />
        </div>
        <div className="flex flex-col items-center px-2">
          <h2 className="text-4xl sm:text-5xl font-bold text-center text-gray-800">¿Qué encontrarás en Netmin?</h2>
          <div
            className="h-2 sm:h-3 w-1/7 rounded-full"
            style={{ background: 'linear-gradient(262deg, #C42126 -0.65%, #FF931E 84.81%)' }}
          ></div>
        </div>
        <section className="flex flex-col items-center gap-8 px-12">
          <LandingInformationItem
            title={informationText[0].title}
            body={informationText[0].body}
            image={informationText[0].image}
          />
          <LandingInformationItem
            title={informationText[1].title}
            body={informationText[1].body}
            image={informationText[1].image}
          />
          <LandingInformationItem
            title={informationText[2].title}
            body={informationText[2].body}
            image={informationText[2].image}
          />
          <LandingInformationItem
            title={informationText[3].title}
            body={informationText[3].body}
            image={informationText[3].image}
          />
        </section>
        <div className="flex flex-col items-center w-full sm:py-4" style={{ backgroundColor: '#FCFCFC' }}>
          <div className="flex gap-4 sm:gap-8 items-center py-10">
            <CounterItem text={'Webinars'} count={100} />
            <div className={'h-8 sm:h-16 bg-gray-300'} style={{ width: 1 }} />
            <CounterItem text={'Usuarios'} count={10000} />
            <div className={'h-8 sm:h-16 bg-gray-300'} style={{ width: 1 }} />
            <CounterItem text={'Organizaciones'} count={100} />
          </div>

          <div className="py-8 border-t border-gray-300 w-full px-8 flex flex-col gap-4 items-center">
            <h3 className="text-3xl sm:text-5xl font-bold text-center text-gray-800">
              ¿Qué estás esperando para ser parte?
            </h3>

            <div className="w-full max-w-sm">
              <Button onClick={scrollUp} className="block w-full mt-3">
                Regístrate
              </Button>
              <OutlinedButton
                onClick={scrollUp}
                borderClassName="border border-orange-500"
                textClassName="text-orange-500 font-bold"
                className="block w-full my-3 px-5 py-2 rounded"
              >
                Inicia Sesión
              </OutlinedButton>
            </div>
            <div className="flex flex-wrap justify-center">
              <a href="https://play.google.com/store/apps/details?id=cl.netmin" target="blank" className="p-1">
                <img src="/static/images/browsers/playstore.png" className="h-10 mx-auto" />
              </a>

              <a href="https://apps.apple.com/us/app/netmin/id1608373176" target="blank" className="p-1">
                <img src="/static/images/browsers/appstore.png" className="h-10 mx-auto" />
              </a>
            </div>
          </div>
        </div>
      </main>

      <footer
        className="w-full py-8 px-16 flex flex-col gap-4 sm:flex-row justify-between items-center"
        style={{ backgroundColor: '#2D2D2D' }}
      >
        <img src="/static/images/netMinWhite.svg" alt="Netmin Logo" className="h-8" />

        <span className="text-white text-center hidden sm:block">
          Netmin es una marca registrada. Todos los derechos reservados | Email: contacto@netmin.cl
        </span>
        <div className="flex flex-col text-white sm:hidden text-center text-sm gap-1">
          <span>Netmin es una marca registrada.</span>
          <span>Todos los derechos reservados</span>
          <span>Email: contacto@netmin.cl</span>
        </div>
      </footer>
    </>
  )
}
