import Image from 'next/image'
import { useGoogleLogin } from 'react-google-login'
import LoadingWrapper from '../../../../generic/LoadingWrapper'
import React from 'react'
import { googleClientID, siteURL } from '../../../../../config'
import clsx from 'clsx'
import { useAuth } from '../../../../../providers/authentication/context'
import useSubmit from './hooks/useSubmit'

export default function SignInWithGoogleButton({ label = 'Iniciar sesión con Google' }) {
  const { signIn: signInNetmin } = useAuth()
  const { handleSubmit, loading } = useSubmit({ onSuccess: signInNetmin })

  const { signIn, loaded } = useGoogleLogin({
    onSuccess: handleSubmit,
    clientId: googleClientID,
    accessType: 'offline',
    scope: 'profile email',
    responseType: 'code',
    redirectUri: siteURL + '/',
  })

  return (
    <LoadingWrapper loading={loading}>
      <button
        onClick={signIn}
        className={clsx(
          'bg-gray-lighter active:bg-gray-dark', // background
          'p-2 w-full', // layout
          'rounded-sm border border-gray-dark', // border
          'flex items-center justify-center space-x-2', // content layout
          loaded ? 'opacity-100' : 'opacity-50 pointer-events-none' // active
        )}
      >
        <Image unoptimized src="/static/images/google-iso.svg" width={22} height={22} priority />
        <p className="text-tertiary-light leading-none truncate font-extrabold">{label}</p>
      </button>
    </LoadingWrapper>
  )
}
