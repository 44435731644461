import useService from '../../../../../../services/hooks/useService'
import AuthenticationService from '../../../../../../services/AuthenticationService'
import useRequest from '../../../../../../services/hooks/useRequest'
import useError from '../../../../../../services/hooks/useError'
import { siteURL } from '../../../../../../config'
import { useLanguage } from '../../../../../../providers/language/context'

export default function useSubmit({ onSuccess }) {
  const authenticationService = useService(AuthenticationService)
  const { exec, loading, error } = useRequest(authenticationService.signInWithGoogle)
  const { locale } = useLanguage()

  useError(error, 'sign-in-oauth')

  const handleSubmit = async (googleObject) => {
    const result = await exec(googleObject.code, siteURL, locale)

    //ON SUCCESS === SIGN-IN FUCTION
    if (result) {
      //console.log('result use submit google', result)
      onSuccess && onSuccess(result.Token)
    }
  }

  return { handleSubmit, loading }
}
