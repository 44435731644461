import { linkedInClientID, linkedInScope, linkedInState, siteURL } from '../config'
export const authLink = 'https://www.linkedin.com/oauth/v2/authorization'
export const accessLink = 'https://www.linkedin.com/oauth/v2/accessToken'

export const getURLWithQueryParams = (base, params) => {
  const query = Object.entries(params)
    .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
    .join('&')

  return `${base}?${query}`
}

export const LINKEDIN_URL = getURLWithQueryParams(authLink, {
  response_type: 'code',
  client_id: linkedInClientID,
  redirect_uri: siteURL,
  state: linkedInState,
  scope: linkedInScope,
})

export const LINKEDIN_URL_ACCOUNT = getURLWithQueryParams(authLink, {
  response_type: 'code',
  client_id: linkedInClientID,
  redirect_uri: siteURL + '/account',
  state: linkedInState,
  scope: linkedInScope,
})
/*
export const LINKEDIN_ACCESS_URL = (authCode) => {
  return getURLWithQueryParams(accessLink, {
    grant_type: 'authorization_code',
    code: authCode,
    redirect_uri: siteURL,
    client_id: linkedInClientID,
    client_secret: linkedInClientSecret,
  })
}
*/
