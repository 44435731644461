import { getAxios } from '../utils/requests'

export default class Service {
  _axios = null
  _authToken = null

  constructor(authToken) {
    this._axios = getAxios(authToken)
    this._authToken = authToken
  }

  _get = async (url) => {
    const response = await this._axios.get(url)
    return response.data.Data || response.data
  }

  _post = async (url, body) => {
    const response = await this._axios.post(url, body)
    return response.data.Data || response.data
  }

  _put = async (url, body) => {
    const response = await this._axios.put(url, body)
    return response.data.Data || response.data
  }

  _delete = async (url, body) => {
    const response = await this._axios.delete(url, { data: body })
    return response.data.Data || response.data
  }
}
