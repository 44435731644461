import React, { createContext, useContext, useEffect, useState } from 'react'
import { activate } from '../../utils/i18n'
import useComponentWillMount from '../../hooks/useComponentWillMount'
import { i18n } from '@lingui/core'
import { I18nProvider } from '@lingui/react'

const LanguageContext = createContext({ enable: () => null })

export const useLanguage = () => useContext(LanguageContext)

export const globalI18n = { locale: 'es' }

export default function LanguageProvider({ children, initialLocale }) {
  const [locale, setLocale] = useState(initialLocale)

  useComponentWillMount(() => {
    activate(initialLocale)
    globalI18n.locale = initialLocale
  })

  useEffect(() => {
    activate(initialLocale)
    setLocale(initialLocale)
    globalI18n.locale = initialLocale
  }, [])

  const enable = (locale) => {
    activate(locale)
    setTimeout(() => location.reload(), 200)
  }

  return (
    <LanguageContext.Provider value={{ locale, enable }}>
      <I18nProvider i18n={i18n}>{children}</I18nProvider>
    </LanguageContext.Provider>
  )
}
