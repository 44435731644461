import Image from 'next/image'
import LoadingWrapper from '../../../../generic/LoadingWrapper'
import React, { useEffect } from 'react'
import clsx from 'clsx'
import { LINKEDIN_URL } from '../../../../../utils/linkedIn'
import WebViewCompatibleLink from '../../../../generic/WebViewCompatibleLink'
import { useAuth } from '../../../../../providers/authentication/context'
import useSubmit from './hooks/useSubmit'
import { useLanguage } from '../../../../../providers/language/context'

export default function SignInWithLinkedIn({ label = 'Iniciar sesión con LinkedIn', query }) {
  const { signIn: signInNetmin } = useAuth()
  const { handleSubmit, loading } = useSubmit({ onSuccess: signInNetmin })

  useEffect(() => {
    if (query && query !== '/' && query.includes('/?code=')) {
      handleSubmit(query.split('/?code=').pop())
    }
  }, [query])

  return (
    <LoadingWrapper loading={loading}>
      <WebViewCompatibleLink href={LINKEDIN_URL} type={'external'}>
        <button
          className={clsx(
            'linkedin-bg', // background
            'p-2 w-full', // layout
            'rounded-sm border border-gray-dark', // border
            'flex items-center justify-center space-x-2', // content layout
            'opacity-100' // active
          )}
        >
          <Image unoptimized src="/static/images/linkedin-2.svg" width={22} height={22} priority />
          <p className="text-white leading-none truncate font-extrabold">{label}</p>
        </button>
      </WebViewCompatibleLink>
    </LoadingWrapper>
  )
}
