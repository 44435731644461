import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import * as yup from 'yup'
import { SelectInput, TextInput } from 'burton'
import useSubmit from './hooks/useSubmit'
import Button from '../../../../generic/Button'
import LoadingWrapper from '../../../../generic/LoadingWrapper'
import { t } from '@lingui/macro'
import { getYear } from 'date-fns'
import { isValidDate } from '../../../../../utils/dates'
import useTimezones from '../../../../../hooks/useTimezones'
import Card from '../../../../generic/Card'
import Avatar from '../../../../generic/Avatar'
import UploadAvatarButton from './component/UploadAvatarButton'
import useModalForm from '../../../account/index/UpdateAvatarButton/hooks/useModalForm'
import useImageSubmit from './hooks/useImageSubmit'

export default function SignUpForm({ email, onSuccess, onReset }) {
  const { options: timezoneOptions, defaultTimezone } = useTimezones()
  const { handleSubmit: handleFormSubmit, control, errors, register } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: { Email: email, Timezone: defaultTimezone },
  })
  const { inputRef, preview, handleChange, handleOpen, updateValues, values, reset } = useModalForm()
  const { editorRef, handleEditedFile, updateImage, editedFile, loading: loadingSubmit } = useImageSubmit({
    onSuccess: reset,
    originalFile: values.file,
  })

  const { handleSubmit, loading } = useSubmit({ onSuccess, updateImage })

  const startYear = getYear(new Date()) - 120

  return (
    <LoadingWrapper loading={loading || loadingSubmit}>
      <form onSubmit={handleFormSubmit(handleSubmit)}>
        <p className="text-sm sm:text-base leading-relaxed tracking-wide text-gray-800">
          Por favor, completa la información requerida para crear tu usuario y acceder:
        </p>

        <Card className="mb-5 relative">
          <Card.Body>
            <div className="flex flex-wrap flex-col sm:flex-row items-center -mx-2 text-center sm:text-left">
              <div className="px-2">
                <Avatar useDefaultImage={true} image={editedFile} className="w-16 h-16 text-white text-2xl " />
              </div>
              <div className="px-2 mt-3 sm:mt-0">
                <UploadAvatarButton
                  inputRef={inputRef}
                  preview={preview}
                  handleChange={handleChange}
                  handleOpen={handleOpen}
                  updateValues={updateValues}
                  values={values}
                  reset={reset}
                  editorRef={editorRef}
                  handleSubmit={handleEditedFile}
                  loading={loadingSubmit}
                />
              </div>
            </div>
          </Card.Body>
        </Card>

        <Controller
          name="Email"
          as={TextInput}
          label="Correo electrónico"
          error={errors.Email?.message}
          control={control}
          disabled
        />
        <div className="flex flex-wrap -mx-2">
          <div className="w-full sm:w-1/2 px-2">
            <Controller
              name="FirstName"
              as={TextInput}
              label="Nombre"
              error={errors.FirstName?.message}
              disabled={loading}
              control={control}
            />
          </div>
          <div className="w-full sm:w-1/2 px-2">
            <Controller
              name="LastName"
              as={TextInput}
              label="Apellidos"
              error={errors.LastName?.message}
              disabled={loading}
              control={control}
            />
          </div>
        </div>

        <div className="bg-yellow-200 border border-yellow-300 rounded-lg p-2">
          <p className="text-yellow-600 text-xs">
            Debes ingresar tu nombre real, de lo contrario, tu cuenta será bloqueada.{' '}
            <b>
              Para crear un perfil de{' '}
              <a
                href="https://forms.gle/J7UYpmqE1P4eURBJ9"
                target="_blank"
                rel="noopener noreferrer"
                className="underline"
              >
                organización debes ingresar aquí
              </a>
              .
            </b>
          </p>
        </div>
        <Controller
          name="Company"
          as={TextInput}
          label="Empresa o institución en la que trabajas"
          error={errors.Company?.message}
          disabled={loading}
          control={control}
        />
        <Controller
          name="Position"
          as={TextInput}
          label="Cargo en la empresa"
          error={errors.Position?.message}
          disabled={loading}
          control={control}
        />

        <Controller
          name="Timezone"
          as={SelectInput}
          label={t`Zona horaria`}
          error={errors.Timezone?.message}
          options={timezoneOptions}
          control={control}
        />

        <p className="leading-relaxed tracking-wide text-gray-800 mt-3">Fecha de nacimiento</p>
        <div className="w-full flex-wrap flex sm:-mx-2 ">
          <div className="w-full sm:w-1/3 sm:px-2">
            <Controller
              name="Day"
              as={SelectInput}
              label={t`Día`}
              error={errors.Day?.message}
              options={Array(31)
                .fill()
                .map((_, index) => ({ key: index + 1, value: index + 1, label: index + 1 }))}
              control={control}
            />
          </div>
          <div className="w-full sm:w-1/3 sm:px-2">
            <Controller
              name="Month"
              as={SelectInput}
              label={t`Mes`}
              error={errors.Month?.message}
              options={[
                { key: '1', value: 1, label: t`Enero` },
                { key: '2', value: 2, label: t`Febrero` },
                { key: '3', value: 3, label: t`Marzo` },
                { key: '4', value: 4, label: t`Abril` },
                { key: '5', value: 5, label: t`Mayo` },
                { key: '6', value: 6, label: t`Junio` },
                { key: '7', value: 7, label: t`Julio` },
                { key: '8', value: 8, label: t`Agosto` },
                { key: '9', value: 9, label: t`Septiembre` },
                { key: '10', value: 10, label: t`Octubre` },
                { key: '11', value: 11, label: t`Noviembre` },
                { key: '12', value: 12, label: t`Diciembre` },
              ]}
              control={control}
            />
          </div>
          <div className="w-full sm:w-1/3 sm:px-2">
            <Controller
              name="Year"
              as={SelectInput}
              label={t`Año`}
              error={errors.Year?.message}
              options={Array(121)
                .fill()
                .map((_, index) => ({
                  key: index + startYear,
                  value: index + startYear,
                  label: index + startYear,
                }))}
              control={control}
            />
          </div>
        </div>

        <p className="leading-relaxed tracking-wide text-gray-800 mt-3">Define tu contraseña:</p>
        <Controller
          name="Password1"
          as={TextInput}
          label="Contraseña"
          type="password"
          error={errors.Password1?.message}
          disabled={loading}
          control={control}
        />
        <Controller
          name="Password2"
          as={TextInput}
          type="password"
          label="Repetir contraseña"
          error={errors.Password2?.message}
          disabled={loading}
          control={control}
        />
        <small className="block leading-tight font-light text-gray-800">
          La contraseña debe tener al menos una letra, un número y un mínimo de 6 caracteres.
        </small>

        <div className="flex flex-row items-center justify-center mt-8">
          <input ref={register} disabled={loading} name="IsTermsAccepted" type="checkbox" id="IsTermsAccepted" />
          <p className="text-black text-lg mx-2 font-bold">
            Acepto los
            <a
              className="text-orange-500 text-lg font-semibold"
              href={'/static/documents/terms.pdf'}
              target="_blank"
              rel="noopener noreferrer"
            >
              {' '}
              Términos y Condiciones
            </a>
          </p>
        </div>
        <small className="block text-center leading-tight font-light" style={{ color: '#ff4d4f' }}>
          {errors?.IsTermsAccepted ? errors.IsTermsAccepted.message : null}
        </small>

        <Button type="submit" className="block w-full mt-5">
          Registrarme
        </Button>
        <button
          type="button"
          className="text-orange-500 active:text-orange-700 block mx-auto mt-1 text-sm"
          onClick={onReset}
        >
          Probar con otro correo electrónico
        </button>
      </form>
    </LoadingWrapper>
  )
}

const validationSchema = yup.object().shape({
  Email: yup
    .string()
    .email('Correo electrónico no es válido')
    .required('Correo electrónico es requerido')
    .transform((val) => (val ? val.trim().toLowerCase() : val)),
  FirstName: yup
    .string()
    .required(<span>Nombre es requerido</span>)
    .transform((val) => (val ? val.trim() : val)),
  LastName: yup
    .string()
    .required(<span>Apellidos son requeridos</span>)
    .transform((val) => (val ? val.trim() : val)),
  Company: yup
    .string()
    .required(<span>Empresa es requerida</span>)
    .transform((val) => (val ? val.trim() : val)),
  Position: yup
    .string()
    .required(<span>Cargo es requerido</span>)
    .transform((val) => (val ? val.trim() : val)),
  Timezone: yup
    .string()
    .required(<span>Zona horaria es requerida</span>)
    .transform((val) => (val ? val.trim() : val)),
  Day: yup
    .number()
    .required(<span>{t`Día es requerido`}</span>)
    .test('is-date-valid', 'La fecha no es válida', function (value) {
      const { Month: month, Year: year } = this.parent
      return isValidDate(year, month ? month - 1 : null, value)
    }),
  Month: yup.string().required(<span>{t`Mes es requerido`}</span>),
  Year: yup.number().required(<span>{t`Año es requerido`}</span>),
  Password1: yup
    .string()
    .required(<span>Contraseña es requerido</span>)
    .matches(/^(?=.*[a-z])/, { message: <span>Ingresa al menos una letra</span> })
    .matches(/^(?=.*[0-9])/, { message: <span>Ingresa al menos un número</span> })
    .matches(/^(?=.{6,})/, { message: <span>Ingresa al menos 6 caracteres</span> }),
  Password2: yup
    .string()
    .required(<span>Repetir contraseña es requerida</span>)
    .oneOf([yup.ref('Password1'), null], <span>Contraseñas no coinciden</span>),
  IsTermsAccepted: yup.bool().oneOf([true], <span>Se deben aceptar los términos y condiciones de uso.</span>),
})
