import React from 'react'
import { toast } from 'react-toastify'
import { Trans } from '@lingui/macro'

export const getStringFromServerError = (error) => {
  if (!error) {
    return error
  }

  const _error = error?.response?.data?.Error || error

  if (_error.Data?.Message) {
    return _error.Data.Message
  } else if (_error.Message) {
    return _error.Message
  } else if (_error.message) {
    return _error.message
  }

  return _error
}

const getFromParams = (from) => {
  const regex = /{([^{}]*)}/gm
  let matches = []
  let match = regex.exec(from)

  while (match) {
    matches.push(match[1])
    match = regex.exec(from)
  }

  return matches
}

const getTo = (to, from) => {
  if (typeof to === 'string') {
    return to
  } else if (typeof to === 'function') {
    return to(getFromParams(from))
  } else {
    return to
  }
}

export const serverErrorToHumanError = (e, context) => {
  const error = getStringFromServerError(e)
  return errorToHumanizedString(error, context)
}

export const handleError = (e, context) => {
  toast.error(serverErrorToHumanError(e, context))
}

export const handleSuccess = (message) => {
  toast.success(message)
}

export const errorToHumanizedString = (from, context) => {
  console.log('Error handler', from)
  if (typeof from === 'string') {
    const error = alerts.find((e) => from.indexOf(e.from) > -1 && (context ? e.context === context : true))
    return error ? getTo(error.to, from) : <div>Ocurrió un problema. Inténtalo más tarde.</div>
  } else {
    return from
  }
}

const alerts = [
  {
    context: 'auth',
    from: 'not found',
    to: <div>Correo electrónico y/o contraseña incorrectos.</div>,
  },
  {
    context: 'sign-up',
    from: 'duplicated: input error',
    to: <div>Ya existe una cuenta registrada con este correo electrónico.</div>,
  },
  {
    context: 'sign-in',
    from: 'wrong credentials: unauthorized',
    to: <div>La contraseña es incorrecta.</div>,
  },
  {
    context: 'sign-in',
    from: 'users not verified: unauthorized',
    to: (
      <div>
        Debes <b>verificar tu cuenta</b> para acceder a NetMin.
      </div>
    ),
  },
  {
    context: 'sign-in-oauth',
    from: 'oauth email in use: duplicated: input error',
    to: (
      <div>
        <Trans> Este email ya esta en uso. </Trans>
      </div>
    ),
  },
  {
    context: 'link-account',
    from: 'pq: duplicate key',
    to: (
      <div>
        <Trans> Ya existe un usuario relacionado con esta cuenta </Trans>
      </div>
    ),
  },
  {
    context: 'account-verify',
    from: 'internal: missing verify token',
    to: <div>Token de verificación no encontrado.</div>,
  },
  {
    context: 'files',
    from: 'internal: file is not video',
    to: <Trans>El archivo seleccionado no es un video.</Trans>,
  },
  {
    context: 'files',
    from: 'internal: file size greater than expected',
    to: <Trans>El archivo es superior al peso permitido.</Trans>,
  },
  {
    context: 'files',
    from: 'internal: file is not image',
    to: <Trans>El archivo seleccionado no es una imagen.</Trans>,
  },
  {
    context: 'files',
    from: 'internal: file size greater than 10MB',
    to: <Trans>El tamaño del archivo no puede ser superior a 10MB.</Trans>,
  },
  {
    context: 'files',
    from: 'internal: file size greater than 8MB',
    to: <Trans>El tamaño del archivo no puede ser superior a 8MB.</Trans>,
  },
  {
    context: 'password-recovery',
    from: 'not found',
    to: <Trans>El enlace de recuperación expiró o ya fue utilizado.</Trans>,
  },
  {
    context: 'files',
    from: 'internal: incompatible-dropped-file',
    to: <Trans>El archivo debe ser una imagen JPEG, PNG o un archivo PDF.</Trans>,
  },
  {
    context: 'files',
    from: 'internal: dropped-file-not-allowed',
    to: <Trans>No se permiten archivos en este formulario.</Trans>,
  },
  {
    context: 'files',
    from: 'internal: dropped-image-not-allowed',
    to: <Trans>No se permiten imágenes en este formulario.</Trans>,
  },
  {
    context: 'video',
    from: 'internal: excedded video max length',
    to: <Trans>No se permiten videos de más de 90 segundos</Trans>,
  },
  {
    context: 'posts',
    from: 'internal: could not create post',
    to: <Trans>No se pudo crear la publicación. Por favor, inténtalo nuevamente.</Trans>,
  },
  {
    context: 'posts',
    from: 'internal: up to 4 images only',
    to: <Trans>Solo se permiten 4 imágenes por publicación.</Trans>,
  },
  {
    context: 'delete-user',
    from: 'error at requesting delete code',
    to: (
      <div>
        <Trans>Error al enviar el codigo de confirmacion para la eliminacion de la cuenta.</Trans>
      </div>
    ),
  },
  {
    context: 'delete-user',
    from: 'delete code is invalid: conflict',
    to: (
      <div>
        <Trans>El código que se ingresó es inválido.</Trans>
      </div>
    ),
  },
  {
    context: 'group-invitation',
    from: 'only admin can invite to a private group: unauthorized',
    to: (
      <div>
        <Trans>Solo un administrador puede invitar en un grupo privado.</Trans>
      </div>
    ),
  },
  {
    context: 'accept-group-invitation',
    from: 'groupInvitation: not found',
    to: (
      <div>
        <Trans>Invitación caducada.</Trans>
      </div>
    ),
  },
]
