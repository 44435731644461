import React, { useState } from 'react'
import LoadingWrapper from '../../../components/generic/LoadingWrapper'
import Card from '../../../components/generic/Card'
import SmallTitle from '../../../components/generic/SmallTitle'
import OutlinedButton from '../../../components/generic/OutlinedButton'
import Button from '../../../components/generic/Button'
import { FiCheck } from 'react-icons/fi'
import classnames from 'classnames'
import { Textarea, TextInput } from 'burton'

export default function ConfirmModal({
  loading,
  title,
  content,
  options,
  textareaLabel,
  textInputLabel,
  onConfirm,
  onConfirmClassName,
  onCancel,
}) {
  const [selectedOption, setSelectedOption] = useState(null)
  const [textareaValue, setTextareaValue] = useState('')
  const [textInputValue, setTextInputValue] = useState('')

  const isDisabled =
    (!selectedOption && !!options) || (!textareaValue && !!textareaLabel) || (!textInputValue && !!textInputLabel)

  const handleClick = (callback) => () => {
    let valueToSend = selectedOption
    if (textareaLabel) {
      valueToSend = textareaValue
    }
    if (textInputLabel) {
      valueToSend = textInputValue
    }
    callback(valueToSend)
  }

  return (
    <Card className="overflow-hidden-z">
      <LoadingWrapper loading={loading}>
        <Card.Body>
          <SmallTitle className="mb-5">{title}</SmallTitle>
          <p className="text-sm text-gray-800 leading-tight">{content}</p>

          {options ? (
            <ul className="divide-y rounded-lg overflow-hidden-z overflow-hidden-z mt-5">
              {options.map((option) => (
                <li key={option.value} className="block">
                  <button
                    onClick={() => setSelectedOption(option.value)}
                    className="flex items-center relative w-full py-3 pl-6 pr-10 text-left text-gray-700 text-sm hover:bg-gray-100 bg-gray-200 bg-opacity-75 rounded"
                  >
                    {option.label}
                    {selectedOption === option.value ? (
                      <div className="absolute top-0 h-full w-10 right-0 flex items-center justify-center text-orange-600">
                        <FiCheck className="text-lg" />
                      </div>
                    ) : null}
                  </button>
                </li>
              ))}
            </ul>
          ) : null}

          {textareaLabel ? <Textarea onChange={setTextareaValue} value={textareaValue} label={textareaLabel} /> : null}

          {textInputLabel ? (
            <TextInput onChange={setTextInputValue} value={textInputValue} label={textInputLabel} />
          ) : null}

          <div className="flex items-center justify-end mt-5">
            <OutlinedButton onClick={handleClick(onCancel)} className="py-2 px-4 text-sm rounded-md mr-3">
              Cancelar
            </OutlinedButton>

            <Button
              disabled={isDisabled}
              onClick={handleClick(onConfirm)}
              className={classnames('text-sm', isDisabled ? 'opacity-50' : null)}
              bgClassName={onConfirmClassName}
              spacingClassName="py-2 px-4"
            >
              Confirmar
            </Button>
          </div>
        </Card.Body>
      </LoadingWrapper>
    </Card>
  )
}
