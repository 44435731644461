import useService from '../../../../../../services/hooks/useService'
import AuthenticationService from '../../../../../../services/AuthenticationService'
import useRequest from '../../../../../../services/hooks/useRequest'
import useError from '../../../../../../services/hooks/useError'
import { pad } from '../../../../../../utils/numbers'

export default function useSubmit({ onSuccess, updateImage }) {
  const authenticationService = useService(AuthenticationService)
  const { execWithCallback, loading, error } = useRequest(authenticationService.signUpWithValues)
  useError(error, 'sign-up')

  const handleSubmit = async (values) => {
    await execWithCallback({
      ...values,
      Language: 'es',
      BirthDate: `${values.Year}-${pad(values.Month, 2)}-${pad(values.Day, 2)}`,
    })(async (response) => {
      updateImage && (await updateImage(response.UUID, response, values.Email, values.Password1, onSuccess))
    })
  }

  return { handleSubmit, loading }
}
