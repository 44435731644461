import React from 'react'
import AccessProcess from '../../components/pages/authentication/access/AccessProcess'
import AccessLayout from '../../components/generic/AccessLayout'

export default function Access() {
  return (
    <AccessLayout>
      <AccessProcess />
    </AccessLayout>
  )
}

Access.permissions = ['anonymous']
