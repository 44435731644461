import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import LoadingWrapper from '../../../../generic/LoadingWrapper'
import { TextInput } from 'burton'
import Button from '../../../../generic/Button'
import * as yup from 'yup'
import useSubmit from './hooks/useSubmit'

export default function SignInForm({ email, onSuccess, onReset, onRecover }) {
  const { handleSubmit: handleFormSubmit, control, errors } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: { Email: email },
  })
  const { handleSubmit, loading } = useSubmit({ email, onSuccess })

  return (
    <LoadingWrapper loading={loading}>
      <form onSubmit={handleFormSubmit(handleSubmit)}>
        <div className="text-gray-800 mb-3">
          <div className="rounded-md bg-gray-200 bg-opacity-75 px-4 py-2 inline-block w-full">
            <p className="text-sm leading-tight text-gray-600">Accediendo como:</p>
            <p className="font-semibold leading-tight text-gray-700">{email}</p>
          </div>
        </div>
        <Controller
          name="Password"
          as={TextInput}
          type="password"
          label="Contraseña"
          error={errors.Password2?.message}
          disabled={loading}
          control={control}
          autoFocus
        />

        <Button type="submit" className="block w-full mt-4">
          Acceder
        </Button>
        <div className="mt-2 flex flex-col sm:flex-row items-center justify-between">
          <button
            type="button"
            className="text-orange-500 active:text-orange-700 mb-4 sm:mb-0 mx-auto text-sm"
            onClick={onReset}
          >
            Probar con otro correo electrónico
          </button>

          <button type="button" className="text-orange-500 active:text-orange-700 mx-auto text-sm" onClick={onRecover}>
            ¿Olvidaste tu contraseña?
          </button>
        </div>
      </form>
    </LoadingWrapper>
  )
}

const validationSchema = yup.object().shape({
  Password: yup.string().required(<span>Contraseña es requerida</span>),
})
