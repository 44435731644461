import useService from '../../../../../../services/hooks/useService'
import AuthenticationService from '../../../../../../services/AuthenticationService'
import useRequest from '../../../../../../services/hooks/useRequest'
import useError from '../../../../../../services/hooks/useError'
import { siteURL } from '../../../../../../config'
import { useLanguage } from '../../../../../../providers/language/context'
import { useRouter } from 'next/router'

export default function useSubmit({ onSuccess }) {
  const authenticationService = useService(AuthenticationService)
  const { exec, loading, error } = useRequest(authenticationService.signInWithLinkedIn)
  useError(error, 'sign-in-oauth')
  const { locale } = useLanguage()
  const router = useRouter()

  const handleSubmit = async (code) => {
    const result = await exec(code, siteURL, locale)

    //ON SUCCESS === SIGN-IN FUCTION
    if (result) {
      router.push(router.pathname, undefined, { shallow: true })
      onSuccess && onSuccess(result.Token)
    }
  }

  return { handleSubmit, loading }
}
