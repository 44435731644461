import Service from './Service'

export default class CompaniesService extends Service {
  findAllTagsByType = async (type) => {
    return await this._get(`/companies/tags?Type=${type}`)
  }

  findAllCompanies = async () => {
    return await this._get('/companies?IsPublished=true&Type=company')
  }

  findAllInstitutions = async () => {
    return await this._get(`/companies?IsPublished=true&Type=institution`)
  }

  findAllInstitutionsByTag = async (tag) => {
    return await this._get(`/companies/by-tag/${tag}?IsPublished=true`)
  }

  findCompanyByUUID = async (uuid) => {
    return await this._get(`/companies/${uuid}?include=Blocks`)
  }

  findBranchesByCompanyUUID = async (companyUUID) => {
    return await this._get(`/companies/branches?CompanyUUID=${companyUUID}`)
  }

  findClosestBranchByCompanyUUIDAndLatAndLng = async (companyUUID, lat, lng) => {
    return await this._get(`/companies/branches/closest?CompanyUUID=${companyUUID}&Latitude=${lat}&Longitude=${lng}`)
  }

  searchCompaniesByQuery = async (query) => {
    return await this._get(`/companies/search?Query=${query}&IsPublished=true&offset=0&limit=10&order=-Similarity`)
  }

  sendVisitToCompanyProfile = async (companyUUID, userData) => {
    return await this._post(`/companies/${companyUUID}/viewers`, { ...userData })
  }

  reportCompanyByUUIDAndReason = async (UUID, reason) => {
    return await this._post(`/companies/${UUID}/report`, { Reason: reason })
  }
}
