import { generateCookie, getCookie } from './cookies'
import jwtDecode from 'jwt-decode'
import AuthenticationService from '../services/AuthenticationService'
import { getAuthToken } from './requests'
import { isProductionENV } from '../config'
import { getStringFromServerError } from './alerts'
import { isAfter, isEqual, parseISO } from 'date-fns'

const systemPermissions = [
  { name: 'user', fallbackPath: '/authentication/access', check: (user) => !!user, enableReturnPath: true },
  { name: 'anonymous', fallbackPath: '/', check: (user) => !user },
  { name: 'any', fallbackPath: '/', check: () => true },
]

const defaultPermissions = ['user']

/**
 * Decode a JWT token and return a value.
 * @param {string} token - JWT token
 * @param {string} key - key to retrieve from decoded token
 * @returns {null|string}
 */
const getKeyFromToken = (token, key) => {
  if (!token) {
    return null
  }

  try {
    const decoded = jwtDecode(token)
    return decoded?.[key]
  } catch (e) {
    console.log('Unable to decode JWT token', e)
    return null
  }
}

/**
 * Obtain token from cookies. Must use req parameter in server side.
 * Then, extract users uuid from the token.
 * @param {any} [req] - Request object provided by Next.js.
 * @returns {null|string}
 */
export const getUserUUID = (req) => {
  const token = getCookie('minex-token', req)
  return getKeyFromToken(token, 'UserUUID')
}

/**
 * Obtain token from cookies. Must use req parameter in server side.
 * Then, extract users email from the token.
 * @param {any} [req] - Request object provided by Next.js.
 * @returns {null|string}
 */
export const getUserEmail = (req) => {
  const token = getCookie('minex-token', req)
  return getKeyFromToken(token, 'Email')
}

/**
 * Obtain users object from the token stored in the cookies.
 * To use this in server side, must provide a req parameter.
 * @param {any} [req] - Request object provided by Next.js.
 * @param {string} [authenticationToken] - Has priority over the token stored in cookies.
 * @returns {Promise<{isValid: boolean, users: *}|null>}
 */
export const getAuth = async (req, authenticationToken) => {
  const token = authenticationToken || getCookie('minex-token', req)

  const authService = new AuthenticationService(token)
  const userUUID = getKeyFromToken(token, 'UserUUID')

  if (!token) {
    return null
  }

  try {
    const user = await authService.findUserAndRolesByUUID(userUUID)
    const isValid = userUUID === user?.UUID

    if (isValid) {
      return user
    }
    return null
  } catch (e) {
    console.log('Unable to load users from cookies.', e)
    return null
  }
}

/**
 * Based on the permissions array defined by a specific route. Returns the related system permissions.
 * @param {array} routePermissions - Array of permissions as strings.
 * @param {boolean} showLog - Set to true if you want to shows logs in dev environment.
 */
export const getRoutePermissions = (routePermissions, showLog = false) => {
  const permissions = routePermissions?.length > 0 ? routePermissions : defaultPermissions
  if (showLog && !isProductionENV) {
    console.log('[DEV] Using permissions:', permissions)
  }
  return permissions.map((rp) => systemPermissions.find((p) => p.name === rp))
}

/**
 * If the users is not allowed to see the route, returns the first unmet permission.
 * @param {array} routePermissions - Array of permissions as strings.
 * @param {object|string} user - Could be an users object or an users UUID.
 * @param {boolean} showLog - Set to true if you want to shows logs in dev environment.
 * @returns {boolean}
 */
export const getFirstUnmetPermissionIfNotAllowed = (routePermissions, user, showLog) => {
  let allowed = false
  let firstUnmet = null
  const permissions = getRoutePermissions(routePermissions, showLog)
  for (let permission of permissions) {
    if (permission.check(user)) {
      allowed = true
    } else if (!firstUnmet) {
      firstUnmet = permission
    }
  }
  return !allowed ? firstUnmet : null
}

/**
 * Checks if there is a JWT token in users cookies.
 * If not, users is redirected to the access page if the component is private.
 * It works in server side and client side.
 * @param {any} ctx - Next.js context.
 * @param {array} routePermissions - Array of permissions as strings.
 * * @param {string} [authenticationToken] - Has priority over the token stored in cookies.
 */
export const checkAuthOrRedirect = (ctx, routePermissions, authenticationToken) => {
  const token = authenticationToken || getAuthToken(ctx.req)
  const userUUID = getKeyFromToken(token, 'UserUUID')
  const firstUnmetPermission = getFirstUnmetPermissionIfNotAllowed(routePermissions, userUUID)
  if (firstUnmetPermission && ctx.res) {
    ctx.res.writeHead(302, {
      Location:
        firstUnmetPermission.fallbackPath + (firstUnmetPermission.enableReturnPath ? `?return=${ctx.asPath}` : ''),
    })
    ctx.res.end()
  }
}

export const checkInternetExplorerRedirect = (ctx) => {
  if (ctx.res && ctx.pathname !== '/authentication/explorer-message') {
    ctx.res.writeHead(302, { Location: '/authentication/explorer-message', check: (user) => !!user })
    ctx.res.end()
  }
}

/**
 * Sign in with a Single SignInToken. If success returns the users object.
 * @param token
 * @returns {Promise<{result: null, error: null}>}
 */
export const signInWithToken = async (token) => {
  const authenticationService = new AuthenticationService()
  let error = null
  let result = null

  if (token) {
    try {
      result = await authenticationService.signInWithSignInTokenUUID(token)
    } catch (e) {
      error = getStringFromServerError(e)
    }
  } else {
    error = 'internal: no sst provided'
  }

  return { result, error }
}

/**
 * Sign in using a Single SignIn Token. If success, saves and returns the users token.
 * @param {object} sst - Single SignIn Token
 * @param {object} res - Response object provided by Next.js
 * @returns {string} - JWT token
 */
export const getUserTokenWithSST = async (sst, res) => {
  const { result, error } = await signInWithToken(sst)

  if (res && result && !error) {
    res.setHeader('Set-Cookie', generateCookie('minex-token', result.Token))
    return result.Token
  }
  return null
}
