import React from 'react'
import Button from '../../../generic/Button'
import OutlinedButton from '../../../generic/OutlinedButton'
import SignInWithGoogleButton from './SignInWithGoogleButton'
import Slider from '../../../generic/Slider'
import SignInWithLinkedIn from './SignInWithLinkedIn'
import { useRouter } from 'next/router'

export default function Home({ onStart }) {
  const router = useRouter()
  const slug = router?.query?.return

  return (
    <div>
      <p className="font-black text-xl text-gray-800 leading-tight mb-12">
        Mantente actualizado/a sobre la industria minera mientras colaboras con profesionales, instituciones y empresas.
      </p>

      <p className="font-black text-gray-800 leading-tight">Únete a Netmin ahora mismo.</p>

      <Button onClick={onStart} className="block w-full mt-3">
        Regístrate
      </Button>
      <OutlinedButton
        onClick={onStart}
        borderClassName="border border-orange-500"
        textClassName="text-orange-500 font-bold"
        className="block w-full my-3 px-5 py-2 rounded"
      >
        Inicia Sesión
      </OutlinedButton>

      <SignInWithGoogleButton />

      <div className="mt-3">
        <SignInWithLinkedIn query={slug} />
      </div>
    </div>
  )
}
