import React from 'react'
import classnames from 'classnames'
import Body from './components/Body'
import Divider from './components/Divider'

export default function Card({ children, className, roundedClassName, shadowClassName, asComponent = 'div', ...rest }) {
  const Component = asComponent
  return (
    <Component
      className={classnames('bg-white', shadowClassName || 'shadow-minex', roundedClassName || 'rounded-xl', className)}
      {...rest}
    >
      {children}
    </Component>
  )
}

Card.Body = Body
Card.Divider = Divider
