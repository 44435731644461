import Image from 'next/image'
import React from 'react'

const LandingInformationItem = ({ title, body, image }) => {
  return (
    <article className="landingItem flex flex-col sm:flex-row flex-1 items-center max-w-sm sm:max-w-2xl md:max-w-5xl gap-8 sm:gap-16">
      <div className="flex-1 px-8 sm:px-0">
        <Image className="rounded-full object-cover" width={356} height={356} fill src={image} />
      </div>
      <div className="flex flex-col gap-6 justify-center landingText w-full" style={{ flex: '2 2 0%' }}>
        <h3 className="text-2xl sm:text-3xl font-semibold text-gray-800">{title}</h3>
        <p className="text-xl sm:text-2xl text-gray-800">{body}</p>
      </div>
    </article>
  )
}

export default LandingInformationItem
