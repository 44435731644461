import { useRef, useState } from 'react'
import useService from '../../../../../../services/hooks/useService'
import useRequest from '../../../../../../services/hooks/useRequest'
import useError from '../../../../../../services/hooks/useError'
import AuthenticationService from '../../../../../../services/AuthenticationService'
import { getAxios } from '../../../../../../utils/requests'

export default function useImageSubmit({ onSuccess, originalFile }) {
  const authenticationService = useService(AuthenticationService)

  //  const { execWithCallback: execFile, loading, error: errorFile } = useRequest(fileService.createFileByUserUUID)
  // const { exec: execUser, error: errorUser } = useRequest(authenticationService.updateUserByUUID)
  // useError(errorFile, 'files')
  // useError(errorUser, 'auth')

  const { execWithCallback: execLogin, error: errorLogin, loading: tokenLoading } = useRequest(
    authenticationService.signInWithEmailAndPassword
  )

  const [newFile, setNewFile] = useState(null)
  const [editedImageURL, setEditedImageURL] = useState(null)
  const [loading, setLoading] = useState(false)

  const editorRef = useRef(null)

  const updateData = async (blob) => {
    const file = new File([blob], originalFile.Temp.name, {
      type: originalFile.Temp.type,
    })
    setEditedImageURL(blobToURL(blob))
    setNewFile(file)
  }

  const updateImage = async (userUUID, user, email, password, callback) => {
    await execLogin(
      email,
      password
    )(async (loginResponse) => {
      const _axios = getAxios(loginResponse.Token)

      const formData = new FormData()
      formData.append('ResourceUUID', userUUID)
      formData.append('File', newFile)
      setLoading(true)
      _axios
        .post('/files', formData)
        .then((file) => {
          const updateObj = { ...user, Photo: file.data.Data ? file.data.Data : file.data }
          _axios
            .put(`/users/${userUUID}`, updateObj)
            .then((userUpdateResponse) => {
              onSuccess && onSuccess()
              callback && callback({ password: password })
              setLoading(false)
            })
            .catch((errorUser) => {
              callback && callback({ password: password })
              useError(errorUser, 'auth')
              setLoading(false)
            })
        })
        .catch((errorFile) => {
          callback && callback({ password: password })
          useError(errorFile, 'files')
          setLoading(false)
        })
    })
  }

  const handleEditedFile = () => {
    const image = editorRef.current?.getImage()
    if (image) {
      image.toBlob(updateData)
    }
  }

  const blobToURL = (blob) => {
    const urlCreator = window.URL || window.webkitURL
    return urlCreator.createObjectURL(blob)
  }

  return { editorRef, updateImage, handleEditedFile, editedFile: editedImageURL, loading: loading || tokenLoading }
}
