import React from 'react'
import Spinner from '../Spinner'

export default function FullScreenLoader() {
  return (
    <div className="w-full h-full absolute bg-orange-500 flex flex-col items-center justify-center">
      <div className="relative">
        <img src="/static/images/netMinISOWhite.svg" alt="NetMin Logo" />
        <div className="absolute left-0 right-0 text-center pt-3">
          <Spinner size={20} />
        </div>
      </div>
    </div>
  )
}
