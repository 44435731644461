import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import * as yup from 'yup'
import useSubmit from './hooks/useSubmit'
import { TextInput } from 'burton'
import Button from '../../../../generic/Button'
import LoadingWrapper from '../../../../generic/LoadingWrapper'
import { Trans } from '@lingui/macro'
import PasswordRecoverySuccess from '../PasswordRecoverySuccess'

export default function PasswordRecoveryForm({ email, onReset }) {
  const [success, setSuccess] = useState(false)
  const { handleSubmit: handleFormSubmit, control, errors } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: { Email: email },
  })
  const { handleSubmit, loading } = useSubmit({ onSuccess: () => setSuccess(true) })

  return (
    <LoadingWrapper loading={loading}>
      {success ? (
        <PasswordRecoverySuccess email={email} onReset={onReset} />
      ) : (
        <form onSubmit={handleFormSubmit(handleSubmit)}>
          <p className="text-sm sm:text-base leading-relaxed tracking-wide font-semibold text-gray-800">
            <Trans>Recuperación de contraseña</Trans>
          </p>
          <p className="text-sm text-gray-800 mb-3">
            <Trans>
              Estás solicitando un cambio en tu contraseña para poder recuperar tu cuenta, por favor confirma el email
              asociado a tu usuario. Recibirás un correo electrónico con instrucciones para reestablecer tu contraseña.
            </Trans>
          </p>
          <Controller
            name="Email"
            as={TextInput}
            label="Correo electrónico"
            error={errors.Email?.message}
            disabled
            control={control}
            autoFocus
          />
          <Button type="html" className="block w-full  mt-3">
            Solicitar
          </Button>
          <button
            type="button"
            className="text-orange-500 active:text-orange-700 block mt-1 mx-auto text-sm"
            onClick={onReset}
          >
            Probar con otro correo electrónico
          </button>
        </form>
      )}
    </LoadingWrapper>
  )
}

const validationSchema = yup.object().shape({
  Email: yup
    .string()
    .email('Correo electrónico no es válido')
    .required('Correo electrónico es requerido')
    .transform((val) => (val ? val.trim() : val)),
})
