import { useMemo } from 'react'

/**
 * Instantiate a service in client side only.
 * @param {class} service - Service class
 * @param {array} deps - Dependencies array
 * @returns {object}
 */
export default function useService(service, deps = []) {
  return useMemo(() => {
    if (process.browser) {
      return new service()
    } else {
      return {}
    }
  }, [process.browser, ...deps])
}
