import React from 'react'
import classnames from 'classnames'

export default function Body({ children, className, ...rest }) {
  return (
    <div className={classnames(className || 'px-6 py-4')} {...rest}>
      {children}
    </div>
  )
}
