import { apiURL } from '../config'
import Axios from 'axios'
import { getCookie } from './cookies'

/**
 * Given a JWT token, build the header object to be used in axios.
 * @param {string} authToken - JWT token
 * @returns {{headers: {authorization: string}}|{}} - header object
 */
const setAuthHeader = (authToken) => {
  if (authToken) {
    return {
      headers: { authorization: `Bearer ${authToken}` },
    }
  }

  return {}
}

/**
 * Axios instance with a base url and base headers. If no token is provided, it will try
 * to extract the authToken from client side cookies.
 * @param {string} authToken - JWT token extracted from cookies
 * @returns {AxiosInstance}
 */
export const getAxios = (authToken) => {
  const _authToken = authToken || getAuthToken()
  return Axios.create({
    baseURL: apiURL,
    ...setAuthHeader(_authToken),
  })
}

/**
 * Extract JWT token from cookies.
 * @param {object} req - Request object provided by Next.js
 * @returns {string} - JWT token
 */
export const getAuthToken = (req) => {
  return getCookie('minex-token', req)
}

/**
 * Run one or more requests and return their result and errors
 * in an abstracted way.
 *  * @param {object} promises - Objects of promises with their respective keys
 * @returns {Promise<object>}
 */
export const runRequests = async (promises) => {
  let resultArray
  let promisesArray = []
  let resultObject = {}

  for (let key in promises) {
    if (Object.prototype.hasOwnProperty.call(promises, key)) {
      promisesArray.push([key, promises[key]])
    }
  }

  resultArray = await Promise.all(promisesArray.map((p) => p[1]))

  if (resultArray) {
    for (let i = 0; i < promisesArray.length; i++) {
      resultObject[promisesArray[i][0]] = resultArray[i] || null
    }
  }

  return resultObject
}
