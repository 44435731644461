import React, { createContext, useContext, useState } from 'react'
import Modal from '../../components/generic/Modal'
import ConfirmModal from './components/ConfirmModal'

const AlertContext = createContext({ show: () => {}, showConfirm: () => {} })
export const useAlert = () => useContext(AlertContext)

export default function AlertProvider({ children }) {
  const [loading, setLoading] = useState(false)
  const [options, setOptions] = useState(null)

  const show = () => {
    setOptions('information')
  }

  const showConfirm = ({
    title,
    content,
    options,
    textareaLabel,
    textInputLabel,
    onConfirm,
    onCancel,
    onConfirmClassName,
  }) => {
    setOptions({
      type: 'confirmation',
      title,
      content,
      options,
      textareaLabel,
      textInputLabel,
      onConfirmClassName,
      onConfirm: (v) => close(v, onConfirm),
      onCancel: (v) => close(v, onCancel),
    })
  }

  const close = async (v, callback) => {
    if (callback) {
      setLoading(true)
      await callback(v)
      setLoading(false)
    }
    setOptions(null)
  }

  return (
    <AlertContext.Provider value={{ show, showConfirm }}>
      {children}

      <Modal animate open={!!options} maxWidth={600} onClose={options?.onCancel}>
        {options ? (
          <ConfirmModal
            loading={loading}
            title={options.title}
            content={options.content}
            options={options.options}
            textareaLabel={options.textareaLabel}
            onConfirm={options.onConfirm}
            onCancel={options.onCancel}
            onConfirmClassName={options.onConfirmClassName}
            textInputLabel={options.textInputLabel}
          />
        ) : null}
      </Modal>
    </AlertContext.Provider>
  )
}
