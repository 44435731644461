import React from 'react'
import classnames from 'classnames'

export default function Container({ children, maxWidth, style, className, horizontalSpacingClassName }) {
  return (
    <div
      className={classnames(
        'block w-full mx-auto box-border relative',
        className,
        horizontalSpacingClassName || 'px-4 md:px-6'
      )}
      style={{ maxWidth: maxWidth, ...style }}
    >
      {children}
    </div>
  )
}

Container.defaultProps = {
  maxWidth: 1430,
}
