import React from 'react'
import 'react-toastify/dist/ReactToastify.css'
import '../styles/tailwind.css'
import '../styles/global.scss'
import '../styles/muxVideoJS.scss'
import '../styles/ck-content-style.css'
import '../styles/webinarvideo.scss'
import '../styles/progressbar.css'
import '../styles/contentInput.css'
import '../styles/swiper.css'
import 'burton/styles/style.css'
import 'react-tippy/dist/tippy.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import 'emoji-mart/css/emoji-mart.css'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/light.css'
import 'swiper/swiper.min.css'
import 'swiper/components/navigation/navigation.min.css'
import 'react-add-to-calendar/dist/react-add-to-calendar.min.css'
import 'react-tabs/style/react-tabs.css'
import '@mux/videojs-kit/dist/index.css'

import ProgressBar from '../components/generic/ProgressBar'
import { Slide, ToastContainer } from 'react-toastify'
import AuthenticationProvider from '../providers/authentication/context'
import AuthRenderer from '../providers/authentication/components/AuthRenderer'
import { ThemeProvider } from 'burton'
import PageManager from '../components/generic/PageManager'
import { useRouter } from 'next/router'
import { getUserLanguage } from '../utils/i18n'
import { checkAuthOrRedirect, checkInternetExplorerRedirect, getAuth, getUserTokenWithSST } from '../utils/auth'
import AlertProvider from '../providers/alert/context'
import StoreProvider from '../providers/store/context'
import LanguageProvider from '../providers/language/context'
import GoogleAnalyticsManager from '../components/generic/GoogleAnalyticsManager'
import * as Sentry from '@sentry/node'
import numbro from 'numbro'
import { SWRConfig } from 'swr'

let cl = require('numbro/dist/languages/es-CL.min')
numbro.registerLanguage(cl, true)

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  Sentry.init({
    enabled: process.env.NODE_ENV === 'production',
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  })
}

export default function CustomApp({ Component, pageProps, language, user, serverISOString, err }) {
  const router = useRouter()

  const page = <Component {...pageProps} err={err} />

  return (
    <SWRConfig value={{ revalidateOnFocus: false, revalidateOnMount: true }}>
      <LanguageProvider initialLocale={language}>
        <StoreProvider
          initialValues={{
            mode: router.query?.mode,
            bottomInset: parseInt(router.query?.bottomInset) || 0,
            serverISOString,
          }}
        >
          <ThemeProvider themeOverride={{ light: { primary: '#FF931E', default: '#edf2f7' } }}>
            <AlertProvider>
              <AuthenticationProvider initialSessionData={user}>
                <ToastContainer transition={Slide} position="top-center" hideProgressBar />
                <AuthRenderer routePermissions={Component.permissions || undefined}>
                  <PageManager page={page} pathname={router.asPath} query={router.query} />
                </AuthRenderer>
                <GoogleAnalyticsManager />
                <ProgressBar />
              </AuthenticationProvider>
            </AlertProvider>
          </ThemeProvider>
        </StoreProvider>
      </LanguageProvider>
    </SWRConfig>
  )
}

CustomApp.getInitialProps = async function ({ Component, ctx }) {
  // If SST is provided, sign in using that token.
  let token = ctx.query?.sst ? await getUserTokenWithSST(ctx.query?.sst, ctx.res) : null

  // Check if browser is internet explorer
  if (ctx.req) {
    const ua = ctx.req.headers['user-agent']
    const msie = ua.indexOf('MSIE ')
    if (msie > 0 || !!ctx.req.headers['user-agent'].match(/Trident.*rv\:11\./)) {
      // if browser is internet explorer, redirect to ie message page
      checkInternetExplorerRedirect(ctx)
    } else {
      // Checks if the users has rights to access the requested page.
      checkAuthOrRedirect(ctx, Component.permissions, token)
    }
  }

  let user
  if (ctx.req) {
    user = await getAuth(ctx.req, token)
  }

  let pageProps = {}
  const lang = getUserLanguage(ctx)

  if (Component.getInitialProps) {
    pageProps = await Component.getInitialProps({ ...ctx, lang })
  }

  return { pageProps, language: lang, user, serverISOString: new Date().toISOString() }
}
