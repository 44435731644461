import { useEffect, useState } from 'react'
import { setCookie } from '../../../utils/cookies'
import { getUserEmail, getUserUUID } from '../../../utils/auth'
import useSWR from 'swr'
import useService from '../../../services/hooks/useService'
import AuthenticationService from '../../../services/AuthenticationService'

export default function useSession({ initialData }) {
  const [email, setEmail] = useState(null)
  const [userUUID, setUserUUID] = useState(initialData?.UUID || null)
  const authenticationService = useService(AuthenticationService, [userUUID])
  const { data, error } = useSWR(
    () => (userUUID ? [userUUID, '/users/[uuid]?roles'] : null),
    authenticationService.findUserAndRolesByUUID,
    { initialData }
  )

  const signIn = (token) => {
    // Expires in 10 years
    setCookie('minex-token', token, 365 * 10)
    setUserUUID(getUserUUID())
    setEmail(getUserEmail())
  }

  const signOut = () => {
    setCookie('minex-token', '', 0)
    setUserUUID(null)
    setEmail(null)
  }

  useEffect(() => {
    setUserUUID(getUserUUID())
    setEmail(getUserEmail())
  }, [])

  const loading = (!!userUUID || userUUID === undefined) && !data && !error

  const isValid = userUUID === data?.UUID

  const user = userUUID ? data : null

  return { user, isValid, email, loading, signIn, signOut }
}
