import Service from './Service'

export default class AnalyticsService extends Service {
  createEvent = async ({ companyUUID, name, resourceUUID, userUUID }) => {
    return await this._post('/analytics/events', {
      CompanyUUID: companyUUID,
      Name: name,
      ResourceUUID: resourceUUID,
      UserUUID: userUUID,
    })
  }

  findCompanyAnalyticsByUUIDAndAggregate = async (companyUUID, aggregate) => {
    return await this._post('/analytics/events/query', {
      CompanyUUID: companyUUID,
      Aggregate: aggregate,
    })
  }
}
