import React, { useRef } from 'react'
import Slide from './components/Slide'
import VerticalReveal from '../VerticalReveal'

export default function Slider({ children, slide = 0 }) {
  const scroll = useRef(null)

  return (
    <VerticalReveal open delay={0} duration={500}>
      <div
        onScroll={() => scroll.current.scrollTo(0, 0)}
        ref={scroll}
        className="w-full whitespace-no-wrap overflow-x-hidden"
      >
        {React.Children.map(children, (child, index) =>
          React.cloneElement(child, {
            currentIndex: slide,
            active: slide === index,
          })
        )}
      </div>
    </VerticalReveal>
  )
}

Slider.Slide = Slide
