import React, { forwardRef } from 'react'
import classnames from 'classnames'

/**
 *
 * @param children
 * @param asComponent
 * @param className
 * @param bgClassName
 * @param spacingClassName
 * @param rest
 */
const Button = forwardRef(function Button(
  { children, asComponent, className, bgClassName, spacingClassName, ...rest },
  ref
) {
  const Element = asComponent || 'button'
  return (
    <Element
      ref={ref}
      className={classnames(
        'text-white rounded-md font-semibold',
        className,
        spacingClassName || 'px-5 py-3',
        bgClassName || 'bg-orange-500 hover:bg-orange-400 active:bg-orange-600'
      )}
      {...rest}
    >
      {children}
    </Element>
  )
})

export default Button
