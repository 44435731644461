import useService from '../../../../../../services/hooks/useService'
import AuthenticationService from '../../../../../../services/AuthenticationService'
import useRequest from '../../../../../../services/hooks/useRequest'
import useError from '../../../../../../services/hooks/useError'
import { siteURL } from '../../../../../../config'

export default function useSubmit({ onSuccess }) {
  const authenticationService = useService(AuthenticationService)
  const { exec, loading, error } = useRequest(authenticationService.requestPasswordRecoveryWithEmailAndReturnURL)
  useError(error, 'password-recovery')

  const handleSubmit = async (values) => {
    const returnURL = siteURL + '/authentication/recovery'
    await exec(values.Email, returnURL)
    onSuccess && onSuccess()
  }

  return { handleSubmit, loading }
}
