import useService from '../../../../../../services/hooks/useService'
import AuthenticationService from '../../../../../../services/AuthenticationService'
import useRequest from '../../../../../../services/hooks/useRequest'
import useError from '../../../../../../services/hooks/useError'

export default function useSubmit({ onSuccess }) {
  const authenticationService = useService(AuthenticationService)
  const { exec, loading, error } = useRequest(authenticationService.checkUserByEmail)
  useError(error, 'auth')

  const handleSubmit = async (values) => {
    const result = await exec(values.Email)
    if (result) {
      onSuccess && onSuccess({ email: values.Email, result })
    }
  }

  return { handleSubmit, loading }
}
