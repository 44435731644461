import React from 'react'
import Animation from '../../../../generic/Animation'
import Button from '../../../../generic/Button'
import useSignIn from './hooks/useSignIn'
import LoadingWrapper from '../../../../generic/LoadingWrapper'

export default function SignUpSuccess({ email = 'marcelo@example.com', password, onSuccess }) {
  const { handleSignIn, loading } = useSignIn({ email, password, onSuccess })

  return (
    <LoadingWrapper loading={loading}>
      <div className="bg-orange-500 rounded-lg p-4 flex items-center justify-center flex-col sm:flex-row">
        <Animation name="success" width={70} height={70} />
        <div className="ml-0 sm:ml-5 mt-3 sm:mt-0 text-white text-center sm:text-left">
          <h2 className="font-semibold leading-tight text-xl sm:text-2xl">¡Muy bien!</h2>
          <h3 className="font-semibold leading-tight text-sm sm:text-base">Ya puedes ingresar a NetMin</h3>
        </div>
      </div>
      <div className="rounded-lg p-4 flex-items-center justify-center bg-gray-200 mt-5">
        <p className="text-gray-800 leading-none text-sm sm:text-base">
          Para aprovechar al máximo todas las funcionalidades, debes verificar tu cuenta. Hemos enviado un correo
          electrónico de verificación a <b>{email}</b>.
        </p>

        <p className="text-gray-800 leading-none mt-5 text-sm sm:text-base">
          Si no encuentras el correo, te recomendamos buscar en tu bandeja de SPAM.
        </p>
      </div>
      <Button className="block w-full mt-5" onClick={handleSignIn}>
        Iniciar sesión
      </Button>
    </LoadingWrapper>
  )
}
