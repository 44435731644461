import React, { createContext, useContext } from 'react'
import useSession from './hooks/useSession'

const AuthenticationContext = createContext({
  user: {},
  isUserValid: null,
  userEmail: null,
  loading: null,
  signIn: () => {},
  signOut: () => {},
})
export const useAuth = () => useContext(AuthenticationContext)

export default function AuthenticationProvider({ children, initialSessionData }) {
  const { user, isValid, email, loading, signIn, signOut } = useSession({ initialData: initialSessionData })
  // console.log(user)
  const value = { user, isUserValid: isValid, userEmail: email, loading, signIn, signOut }

  return <AuthenticationContext.Provider value={value}>{children}</AuthenticationContext.Provider>
}
