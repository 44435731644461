import { useEffect } from 'react'
import { useRouter } from 'next/router'
import { registerGoogleAnalyticsPageView } from '../../../utils/analytics'
import { useAuth } from '../../../providers/authentication/context'

export default function GoogleAnalyticsManager() {
  const router = useRouter()
  const { user } = useAuth()

  useEffect(() => {
    const handleRouteChange = (url) => {
      registerGoogleAnalyticsPageView(url, user?.UUID)
    }
    router.events.on('routeChangeComplete', handleRouteChange)

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  return null
}
