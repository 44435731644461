import React, { useEffect } from 'react'
import { useAuth } from '../context'
import { useRouter } from 'next/router'
import FullScreenLoader from '../../../components/generic/FullScreenLoader'
import { getFirstUnmetPermissionIfNotAllowed } from '../../../utils/auth'
import { isAfter, isEqual, parseISO } from 'date-fns'

export default function AuthRenderer({ routePermissions = [], children }) {
  const { user, loading } = useAuth()
  const router = useRouter()

  useEffect(() => {
    const checkProfileData = () => {
      const hasFirstName = !!(user?.FirstName && user?.FirstName.length > 0)
      const hasLastName = !!(user?.LastName && user?.LastName.length > 0)

      const hasCompany = !!(user?.Company && user?.Company.length > 0)
      const hasPosition = !!(user?.Position && user?.Position.length > 0)

      //const hasBio = !!(user?.Biography && user?.Biography.length > 0)

      const hasBirthDate = !!(user?.BirthDate && user?.BirthDate.length > 0)

      const hasAllData = hasFirstName && hasLastName && hasCompany && hasPosition && hasBirthDate

      if (!hasAllData && !router.pathname.includes(`/authentication`)) {
        if (user) {
          router.push(`/authentication/complete-account`)
        }
      }
    }

    const checkTOS = () => {
      if (
        user?.Included?.Policy &&
        (!user.TermsAcceptedAt ||
          !(
            isAfter(parseISO(user.TermsAcceptedAt), parseISO(user?.Included?.Policy?.UpdatedAt)) ||
            isEqual(parseISO(user.TermsAcceptedAt), parseISO(user?.Included?.Policy?.UpdatedAt))
          ))
      ) {
        if (!router.pathname.includes(`/authentication`)) {
          router.push(`/authentication/accept-tos`)
        }
      }
    }

    if (!loading) {
      checkTOS()
      checkProfileData()
    }
  }, [loading, children, user])

  useEffect(() => {
    const checkPermissions = () => {
      const firstUnmetPermission = getFirstUnmetPermissionIfNotAllowed(routePermissions, user, true)
      if (firstUnmetPermission) {
        if (router.query.return) {
          router.push(router.query.return)
        } else {
          router.push(
            firstUnmetPermission.fallbackPath +
              (firstUnmetPermission.enableReturnPath ? `?return=${router.asPath}` : '')
          )
        }
      }
    }

    if (!loading) {
      checkPermissions()
    }
  }, [loading, routePermissions, children, user])

  return loading || !!getFirstUnmetPermissionIfNotAllowed(routePermissions, user) ? <FullScreenLoader /> : children
}
